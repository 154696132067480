import React from "react";
import Seo from "../../../components/Seo";
import Layout from "../../../components/Layout";
import { graphql } from "gatsby";
import { HTMLContent } from "../../../components/Content";
import Vehicle, { isActive } from "./Vehicle";

const Template = ({ data, location }) => {
  const {
    html,
    frontmatter: { title, dispatcherName, active },
  } = data.markdownRemark;

  let seoDescriptionTime = "";
  if (!isNaN(active.from)) {
    seoDescriptionTime = isActive(active)
      ? `, welches seit ${active.from} im Einsatz ist,`
      : `, welches von ${active.from} bis ${active.to} im Einsatz war,`;
  }

  return (
    <Layout>
      <Seo
        title={`${title} | Fahrzeuge | Über uns`}
        pathname={location.pathname}
        description={`Alles über die Ausstattung und Daten von unserem ${title} mit dem Funkrufnamen ${dispatcherName}${seoDescriptionTime} erfahren.`}
      />
      <Vehicle
        content={html}
        contentComponent={HTMLContent}
        title={title}
        dispatcherName={dispatcherName}
        active={active}
        location={location}
      />
    </Layout>
  );
};

export default Template;

export const query = graphql`
  query VehiclePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        dispatcherName
        active {
          from
          to
        }
      }
    }
  }
`;
