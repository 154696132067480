/** @jsxImportSource theme-ui */
import React from "react";
import Content from "../../../components/Content";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { BaseStyles } from "theme-ui";

export const isActive = (active) => active && !active?.to;

const Vehicle = ({
  content,
  contentComponent,
  title,
  dispatcherName,
  active,
  location,
}) => {
  const ContentRenderer = contentComponent || Content;

  return (
    <BaseStyles>
      <Breadcrumbs
        names={["Über uns", "Fahrzeuge", title]}
        location={location}
      />

      <article sx={{ maxWidth: "container" }}>
        <h2 sx={{ gridArea: "header" }}>{title}</h2>

        <p>{dispatcherName}</p>
        {!isNaN(active.from) && (
          <p>
            {isActive(active)
              ? `seit ${active.from} im Einsatz`
              : `war im Einsatz von ${active.from} bis ${active.to}`}
          </p>
        )}

        <ContentRenderer content={content} sx={{ gridArea: "main" }} />
      </article>
    </BaseStyles>
  );
};

export default Vehicle;
